body {
    overflow-y: overlay;
}

.certificado-screen {
    background: #f0f0f0;
    min-height: 100vh;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.container-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    gap: 2rem;
}

.download-button {
    position: fixed;
    /* bottom: 20px; */
    /* left: auto; */
    /* width: 201px; */
    /* height: 60px; */
    padding: 1rem;
    font-size: 16px;
    background: #60159a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
    text-decoration: none;
}

.download-button:hover {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: #9c44dc;
}

.download-button:focus {
    text-decoration: none;
    color: #fff;
}

.download-button:active {
    background: #9c44dc;
    color: #fff;
}

.certificado {
    min-height: 1123px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    margin: 0;
    padding: 0;
    border-radius: 0px 0px 10px 10px;
}

header {
    width: 100%;
    height: 80px;
    border-bottom: 2px solid #60159a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background: #fff;
    padding: 0 12rem;
}

header>img {
    width: 300px;
    transform: scale(0.8);
}

.info-container {
    display: flex;
    flex-direction: column;
}


.processo-info-container {
    margin-bottom: 20px;
    color: #5d5d5d;
}


.documentos-info-container {
    margin-bottom: 20px;
}

.documentos-info-container>h3 {
    font-size: 20px;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(239, 239, 239);
    color: #60159a;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.documentos-info-container>ul>li {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    color: #5d5d5d;
    /* color: #333; */
    margin-bottom: 10px;
    padding: 1rem 3rem;
}


.signatarios-info-container {
    margin-bottom: 20px;
    width: 100%;
}

.signatarios-info-container>h3 {
    font-size: 20px;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(239, 239, 239);
    color: #60159a;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.signatarios-info-container>ul>li {
    width: 100%;
    background: #f9f9f9;
    border-radius: 5px;
    /* color: #333; */
    color: #5d5d5d;
    margin-bottom: 10px;
    padding: 10px;
    padding: 1rem 3rem;
}


.content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    height: 100%;
}

.content-container>h2 {
    margin-top: 5px;
    font-weight: 700;
    text-transform: uppercase;
}

.eventos-container>h3 {
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    background-color: rgb(239, 239, 239);
    color: #60159a;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.evento {
    width: 100%;
    background: #f9f9f9;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #5d5d5d;
    padding: 1rem 3rem;
}

.evento-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.evento-icon-container>svg {
    font-size: 20px;
}

.evento>div {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.evento>div>p>strong {
    font-weight: 900;
}

.link {
    color: #8b8b8b;
}

.link:link {
    color: #60159a;
    text-decoration: none;
}

footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #60159a;
    padding: 20px 0;
    background: #fff;
    margin-top: 10px;
    border-radius: 0px 0px 10px 10px;
}

footer>a {
    text-decoration: none;
    color: #5d5d5d;
}

.strong {
    font-weight: 900;
    word-break: break-all;
}

.modal-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    width: 100%;
}

.assinaturas-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 30px;
}

.assinatura-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}

.assinaturas-container>h3 {
    font-size: 20px;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(239, 239, 239);
    color: #60159a;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}